<template>
  <div class="detail">

    <div v-if="Object.keys(groupForm).length>0">
      <div v-for="(items,k,i) in groupForm" v-bind:key="'form'+i">
        <div class="form-title" style="position:relative;padding-right: 40px;">
          {{k}}
        </div>
        <div v-for="(obj,key) in items" v-bind:key="'items'+ key">
          <div>
            <div v-if="obj.type=='radio'">
              <div class="item-title">
                <span class="item-index">{{(key+1)}}</span>
                <span class="item-name">{{  obj.name}}</span>
                <span class="item-check">
                  <van-radio-group v-model="formData[obj.id]['value']" direction="horizontal" disabled>
                    <van-radio name="1" icon-size="20px">是</van-radio>
                    <van-radio name="0" icon-size="20px">否</van-radio>
                  </van-radio-group>
                </span>
              </div>
              <div v-if="formData[obj.id]['value']=='0'">
                <div>
                  <div style="padding: 10px;">整改问题及要求</div>
                  <div class="item-check-des">{{formData[obj.id]['des']}}</div>
                  <div class="form-label"><span></span>检查照片</div>
                  <div v-if="fileList[obj.id] && fileList[obj.id].length>0" style="padding: 8px 16px 0px;">
                    <van-uploader v-model="fileList[obj.id]" :max-count="fileList[obj.id].length" :deletable="false"  />
                  </div>
                  <div v-else style="padding: 8px 16px 0px;">
                    无
                  </div>
                </div>
                <div class="rectify-content" >
                  <div class="rectify-title">整改情况</div>
                  <van-field name="remark" rows="3"
                             :rules="[{ required:true, message: '',ruleId:obj.id  }]"
                             v-model="rectify[obj.id]['des']"
                             autosize type="textarea"  maxlength="200" show-word-limit label="" placeholder="整改反馈" />
                  <div class="form-label"><span></span>整改照片</div>
                  <div v-if="rectify[obj.id]['pics'].length>0" style="padding: 8px 16px 0px;">
                    <van-uploader  v-model="rectify[obj.id]['pics']"  :max-count="rectify[obj.id]['pics'].length" :deletable="false"  />
                  </div>
                  <div v-else style="padding: 8px 16px 0px;">
                    无
                  </div>
                </div>
              </div>
              <div v-else>
                <div v-if="formData[obj.id]['urls'] && formData[obj.id]['urls'].length>0" style="padding: 8px 16px 0px;">
                  <van-uploader v-model="formData[obj.id]['urls']" :max-count="formData[obj.id]['urls'].length" :deletable="false"  />
                </div>
              </div>
            </div>
            <div v-else-if="obj.type=='textarea'">

              <van-field
                  label-width="7rem"
                  v-model="formData[obj.id]['des']"
                  :name="obj.id"
                  :label="(key+1) +'.' + obj.name"
                  :placeholder="obj.name"
                  :rules="[{ required: false, message: '必填'}]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-label" style="padding-bottom: 5px;"><span></span>企业签名</div>
    <div v-if="recordData.checkedSign" style="border: 1px solid #ebedf0;height: 80px;margin: 8px 16px;border-radius: 4px;" >
      <img :src="recordData.checkedSign" height="80px">
    </div>
    <div v-else style="padding: 8px 16px 0px;">
      无
    </div>


    <div class="form-label" style="padding-bottom: 5px;"><span></span>现场照片</div>
    <van-field name="uploader" label="" v-if="pics.length>0">
      <template #input>
        <van-uploader v-model="pics" :max-count="pics.length" :deletable="false"  />
      </template>
    </van-field>
    <div v-else style="padding: 8px 16px 0px;">
      无
    </div>
    <div class="form-label" style="padding-bottom: 5px;"><span></span>上报人</div>
    <van-field
        name="check"
        v-model="recordData.remark"
        label=""
        disabled
        placeholder="上报人"
    />

    <div class="form-label" style="padding-bottom: 5px;"><span></span>上报时间 <span style="float:right;padding-right:20px">{{recordData.createTime?recordData.createTime.substr(0,16):""}}</span></div>

  </div>
</template>
<script>
import {
  Button, Col, Row,Uploader ,Image,Icon,RadioGroup, Radio,Form,Field,Notify,Dialog,NoticeBar,Popup
} from 'vant';
export default {
  data(){
    return {
      labelWidth:window.innerWidth - 165,
      formData:{},
      check:{},
      fileList:{},
      rectify:{},
      person:"",
      loading:false,
      pics:[],
      groupForm:{},
      customer:[]
    }
  },
  components:{
    [Button.name]: Button,
    [Uploader.name]: Uploader  ,
    [Icon.name]: Icon ,
    [Form.name]: Form,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Image.name]: Image ,
    [Notify.name]: Notify,
    [Popup.name]: Popup,
    [NoticeBar.name]: NoticeBar,
    [Dialog.name]: Dialog,
    [Dialog.Component.name]: Dialog.Component,
    [Col.name]: Col,
    [Row.name]: Row
  },
  props:{
    recordData:{
      type:Object,
      default: ()=>{
        return {
        }
      }
    },
    dict:{
      type:Object,
      default: ()=>{
        return {
          id:"yqfk",
          typeList:[]
        }
      }
    },
  },
  watch: {
    /*监听主题变化*/
    recordData: {
      handler(n, o) {
        if(this.dict){
          this.renderDict();
        }
        this.formData = {};
        this.pics = [];
        if(n){
          let json = JSON.parse(n.content);
          if(n.picture){
            let p = n.picture.split(",");
            p.map(item=>{
              this.pics.push({
                url:item,
                isImage:true
              })
            })
          }
          for(let k in json){
            if(json[k].urls){
              json[k].urls = json[k].urls.split(",").map(url=>{
                return {
                  url:url,
                  isImage: true
                }
              })
            }
          }
          this.formData = json;
          console.log(this.formData,"usrss")
          this.rectify = {};
          this.fileList = {};

          n.problemList.map(problem=>{
            let pics = [];
            let rectifyPics=[];
            if(problem.rectifyFileList){
              problem.rectifyFileList.map(pic=>{
                rectifyPics.push({
                  url:pic,
                  isImage: true
                })
              })
            }

            this.$set(this.rectify,problem.typeId,{
              des:problem.rectifyResult,
              id:problem.id,
              pics:rectifyPics,
              save:rectifyPics.length>0?true:false,
              checkResult:problem.checkResult,
              createTime:problem.createTime,
              typeId:problem.typeId
            })
            if(problem.recordFileList){
              problem.recordFileList.map(pic=>{
                pics.push({
                  url:pic,
                  isImage: true
                })
              })
            }

            this.$set(this.fileList,problem.typeId,pics);
          })

        }
      },
      deep: true,
      immediate:true
    },
    dict:{
      handler(n, o){
      },
      deep: true,
      immediate:true
    }
  },
  mounted(){

  },
  methods:{
    renderDict(){
      console.log(this.dict,"dict");
      let items = this.dict.typeList;
      let group = {};
      items.map((item,index)=>{
        if(!item.groupName){
          item.groupName = "其他";
        }
        if(!group[item.groupName]){
          group[item.groupName] = [];
        }
        group[item.groupName].push(item);
        if(index==0){
          this.firstFormName = item.id;
        }

      })
      this.formData = {};
      for(let k in group){
        group[k].map((item,index)=>{
          if(!this.formData[item.id]){
            this.$set(this.formData,item.id,{
              value:"1",
              des:"",
              groupName:k,
              name:item.name,
              type:item.type
            })
            this.$set(this.fileList,item.id,[])
          }
        })
      }
      this.$set(this.formData,"check",{
        value:null,
        des:""
      })
      this.groupForm = group;
    }


  }
}
</script>

<style scoped lang="less">
.detail{
  padding-bottom: 40px;
  background: #f4f6fa;
}
.form-title{
  font-size: 1.1rem;
  color:#4E555B;
  line-height: 40px;
  padding: 5px 16px 0px;
}

.form-label{
  padding: 10px 12px 0px;
  color: #646566;
}
.item-list{
  padding: 0px 16px;
}
.item{
  position: relative;
  margin: 0px 16px 2px;
}
.hasRectify{
  margin-bottom: 8px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 2px 6px 0px rgba(225, 230, 236, 100);
}
.item-title{
  //color: #646566;
  color:#1E6CF7;
  padding: 10px 0px;
  font-size:1rem;
  border-bottom: 1px solid #eee;
  position: relative;

  .item-index{
    position: absolute;
    left: 2px;
    //top: 12px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    background: #40a9ff;
    text-align: center;
    color: white;
    border-radius: 50%;
  }

  .item-name{
    display: inline-block;
    position: relative;
    top: -2px;
    padding-left: 24px;
    width: calc(100vw - 165px);
  }
  .item-check{
    position: absolute;
    //top: 0px;
    right: -12px;
  }
}
.item-check-des{
  padding:5px 10px;
  color: #F7671E;
  font-size:1rem;

}
.rectify-content{
  //border: 1px solid rgba(0,0,0,0.1);
  padding-bottom:10px;

  .item-btn{
    padding: 20px 20px;
  }
}
.rectify-title{
  line-height: 40px;
  padding: 0px 10px;

}
/deep/ .van-radio-group--horizontal{
  width: 110px;
}
/deep/ .van-field__body textarea{
  background: rgba(244, 246, 250, 100);
  padding: 10px;
}
/deep/ .van-radio--horizontal{
  margin-right: 5px;
}
</style>
