<template>
  <div style="position:relative;width: calc(100vw);height: calc(100vh - 50px);overflow-y: scroll;">

    <van-form @submit="onSubmit" @failed="failed" :label-width="labelWidth" ref="checkForm">
      <van-notice-bar
          v-if="tempshow"
          :scrollable="false"
          type="success"
          :text="tempTime"
      />
      <div v-if="Object.keys(groupForm).length>0">
        <div v-for="(items,k,i) in groupForm" v-bind:key="'form'+i">
          <div class="form-title" style="position:relative;padding-right: 40px;">
            {{k}}
<!--            <span v-if="k=='根据机位设置情况抽查实名登记上网执行情况'" style="position: absolute;right:8px;top: 8px;">
               <van-icon  name="add-o" size="30" color="#1989fa" @click="addCheck(items)"/>
            </span>-->
          </div>

          <div v-for="(obj,key) in items" v-bind:key="'items'+ key">
<!--            <div v-if="k!='根据机位设置情况抽查实名登记上网执行情况'">-->
            <div>
              <div v-if="obj.type=='radio'">
                <van-field
                    :name="obj.id"
                    :label="(key+1) +'.' + obj.name"
                    :placeholder="obj.name"
                    :rules="[{ required: true, message: '必填'}]"
                >
                  <template #input>
                    <span class="label-tips">*</span>
                    <van-radio-group v-model="formData[obj.id]['value']" direction="horizontal">
                      <van-radio name="1" icon-size="24px">是</van-radio>
                      <van-radio name="0" icon-size="24px">否</van-radio>
                    </van-radio-group>
                  </template>
                </van-field>
                <div>
                  <van-field name="remark" rows="3" autosize v-show="formData[obj.id]['value']==='0'"
                             :rules="[{ validator, message: '',ruleId:obj.id  }]"
                             type="textarea" v-model="formData[obj.id]['des']" maxlength="200" show-word-limit label=""
                             placeholder="检查出的问题及整改要求">
                    <span class="label-tips">*</span>
                  </van-field>
                  <div class="form-label"><span style="padding-right: 5px;color: red;position:relative;top: 1px;font-size: 0.8rem;">*</span><span></span>拍照上传</div>
                  <div class="form-image">
                    <van-field name="uploader" :rules="[{ validator:validatorPic, message: '至少上传一张照片', ruleId:obj.id  }]">

                      <template #input>
                        <van-uploader v-model="fileList[obj.id]"  capture="camera" :before-read="beforeRead" :after-read="afterRead(obj.id)" :max-count="6" />
                      </template>
                    </van-field>
                  </div>

                </div>
              </div>
              <div v-else-if="obj.type=='textarea'">

                <van-field
                    label-width="7rem"
                    v-model="formData[obj.id]['des']"
                    :name="obj.id"
                    :label="(key+1) +'.' + obj.name"
                    :placeholder="obj.name"
                    :rules="[{ required: false, message: '必填'}]"
                />
              </div>
            </div>
<!--            <div v-else>
              <div v-if="obj.type=='radio'">
                <van-field
                    :name="obj.id"
                    :label="(key+1) +'.' + obj.name"
                    :placeholder="obj.name"
                    :rules="[{ required: false, message: '必填'}]"
                >
                  <template #input>
                    <van-radio-group v-model="formData[obj.id]['value']" direction="horizontal">
                      <van-radio name="1" icon-size="24px">是</van-radio>
                      <van-radio name="0" icon-size="24px">否</van-radio>
                    </van-radio-group>
                  </template>
                </van-field>
              </div>
              <div v-else-if="obj.type=='textarea'">
                <van-field
                    label-width="7rem"
                    v-model="formData[obj.id]['des']"
                    :name="obj.id"
                    :label="(key+1) +'.' + obj.name"
                    :placeholder="obj.name"
                    :rules="[{ required: false, message: '必填'}]"
                />
              </div>
              <div v-else>
              </div>
            </div>-->

          </div>
        </div>

<!--        <div v-if="customer">
          <div v-for="(c,i) in customer" v-bind:key="'customer'+i">
            <van-divider/>
            <div v-for="(o,m) in c" v-bind:key="'customer-o'+m">
              <div v-if="o.type=='radio'">
                <van-field
                    :name="o.id"
                    :label="(m+1) +'.' + o.name"
                    :placeholder="o.name"
                    :rules="[{ required: false, message: '必填'}]"
                >
                  <template #input>
                    <van-radio-group v-model="o.value" direction="horizontal">
                      <van-radio name="1" icon-size="24px">是</van-radio>
                      <van-radio name="0" icon-size="24px">否</van-radio>
                    </van-radio-group>
                  </template>
                </van-field>
                <div class="form-label"><span style="padding-right: 5px;color: red;position:relative;top: 1px;font-size: 0.8rem;">*</span><span></span>拍照上传</div>
                <div class="form-image">
                  <van-field name="uploader" :rules="[{ validator:validatorPic, message: '至少上传一张照片', ruleId:o.id  }]">

                    <template #input>
                      <van-uploader v-model="fileList[o.id]"  capture="camera" :before-read="beforeRead" :after-read="afterRead" :max-count="6" />
                    </template>
                  </van-field>
                </div>
              </div>
              <div v-else-if="o.type=='textarea'">
                <van-field
                    label-width="7rem"
                    v-model="o.value"
                    :name="o.id"
                    :label="(m+1) +'.' + o.name"
                    :placeholder="o.name"
                    :rules="[{ required: false, message: '必填'}]"
                />
              </div>
            </div>
          </div>
        </div>-->
      </div>



      <div class="form-label" style="padding-bottom: 5px;"><span></span>企业签名</div>
      <div>
        <div style="border: 1px solid #ebedf0;height: 80px;margin: 8px 16px;border-radius: 4px;" @click="openSign('checkedSign')">
          <div v-if="checkedSigned">
            <img :src="venueData.checkedSign" height="80px">
          </div>
        </div>
      </div>

      <div class="form-label" style="padding-bottom: 5px;"><span></span>上报人</div>
      <van-field
          name="check"
          v-model="venueData.remark"
          label=""
          placeholder="上报人"
      />
      <div class="form-label">现场照片</div>
      <div class="form-image">
        <van-field name="uploader">
          <template #input>
            <van-uploader v-model="pics" :before-read="beforeRead" :after-read="sendFile" :max-count="6" />
          </template>
        </van-field>
      </div>
      <div style="height: 60px;"></div>
      <div style="margin-bottom:10px;width: calc(100vw);">
        <van-row  type="flex" justify="center"  gutter="0">
          <van-col :span="venue.checkType!='1'?12:24">
            <van-button size="large" type="primary" :loading="loading" native-type="submit">提交</van-button>
          </van-col>
        </van-row>

      </div>
    </van-form>
    <van-dialog v-model="show" title="确认提交？" show-cancel-button @cancel="cancleConfirm" @confirm="confirm">
      <van-notice-bar
          v-if="noticeShow"
          :scrollable="false"
          :text="des"
      />
      <div style="padding: 10px 0px 16px;">
        <div class="confirm-item" v-for="(obj, key) in confirmForm" v-bind:key="key">
          {{obj.sort +'.' + obj.name}}
        </div>
      </div>

    </van-dialog>
    <van-popup v-model="signShow" position="bottom" :style="{ height: '100%' }" :closeable="true" get-container="#app" >
      <signature @getSign="getSign" :signer="signer"></signature>
    </van-popup>
    <van-popup v-model="successPop" position="right" :style="{ height: '100%',width:'100%' }">

      <div >
        <div class="model-title">
          <van-icon name="arrow-left" size="28" />
        </div>
        <div class="success-icon">
          <van-icon name="passed" color="#00E68F" size="40" />
        </div>
        <div class="success-content" v-if="temp">
          暂存成功，{{seconds}}s<a href="javascript:void(0)" @click="goToMap">返回主页</a>
        </div>
        <div class="success-content" v-else>提交成功，{{seconds}}s<a href="javascript:void(0)" @click="goToMap">返回主页</a>
          <!--          ,查看<a href="javascript:void(0)" @click="viewDetail">检查详情</a>-->
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {RenderCheckDict} from "@/api/dict"
import signature from "../map/components/signature";
import {
  Toast,Button, Col, Row,Uploader ,Image,Icon,RadioGroup, Radio,Form,Field,Notify,Dialog,NoticeBar,Popup
} from 'vant';
import Compressor from 'compressorjs';
export default {
  components:{signature,
    [Toast.name]: Toast,
    [Button.name]: Button,
    [Uploader.name]: Uploader  ,
    [Icon.name]: Icon ,
    [Form.name]: Form,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Image.name]: Image ,
    [Notify.name]: Notify,
    [Popup.name]: Popup,
    [NoticeBar.name]: NoticeBar,
    [Dialog.name]: Dialog,
    [Dialog.Component.name]: Dialog.Component,
    [Col.name]: Col,
    [Row.name]: Row
  },
  props:{
    venue:{
      type:Object,
      default: ()=>{
        return {
          name:"场馆1",
          id:"1"
        }
      }
    },
    dict:{
      type:Object,
      default: ()=>{
        return {
          typeList:[],
          id:"1"
        }
      }
    },
  },
  computed: {
    tempshow() {
      return this.tempTime?true:false;
    }
  },
  watch: {
    /*监听主题变化*/
    venue: {
      handler(n, o) {
        console.log(n,"report")
        this.venueData.infoId = n.id;
        this.venueData.status = -1;
        this.venueData.checkType = n.checkType;
        this.venueData.checkerSign = "";
        this.venueData.checkedSign = "";
        this.venueData.checkerSigned = false;
        this.venueData.checkedSigned = false;
        this.pics = [];
        this.successPop = false;
        this.seconds = 5;
      },
      deep: true
    },
    dict: {
      handler(n, o) {
        console.log(n,"dictdict")
        this.getDictList();
      },
      deep: true,
      immediate:true
    }
  },
  data(){
    return {
      signShow:false,
      labelWidth:window.innerWidth - 165,
      formDict1:{},
      formDict2:{},
      formDict3:{},
      venueData:{
        remark: GlobalConfig.dev?GlobalConfig.userName:localStorage.getItem("wljptuserName"),
        rectify:"",
        type:"yqfk",
        infoId:this.venue.id,
        checkerSign:"",
        checkedSign:"",
        status:-1,
        person:GlobalConfig.dev?GlobalConfig.openid:localStorage.getItem("wljptuser")
      },
      pics:[],
      fileList:{},
      formData:{
        // "0001": null,
        // "0002": null,
        // "0003": null,
        // "0004": null,
        // "0005": null,
        // "0006": null,
        // "0007": null,
        // "0008": null,
        // "0009": null,
        // "0010": null,
        // "0011": null,
        // "0012": null,
        // "0013": null,
        // "0014": null,
        // "0015": null,
        // "0016": null,
        // "0017": null,
        "check":""
      },
      uploader:[],
      loading:false,
      noticeShow:false,
      show:false,
      successPop:false,
      checkerSigned:false,
      checkedSigned:false,
      temp:true,
      des:"",
      confirmForm:{},
      seconds:5,
      checkData:{},
      timer:null,
      timeOuter:null,
      tempTime:"",
      fileTest:[],
      signer:"",
      picTempId:"",
      groupForm:{},
      customer:[]
    }
  },
  methods:{
    // 获取检查项
    getDictList(){
      console.log(this.dict,"dict");
      this.pics = [];
      this.venueData.checkedSign = "";
      let items = this.dict.typeList;
      this.venueData.type = this.dict.id;
      let group = {};
      items.map((item,index)=>{
        if(!item.groupName){
          item.groupName = "其他";
        }
        if(!group[item.groupName]){
          group[item.groupName] = [];
        }
        group[item.groupName].push(item);
        if(index==0){
          this.firstFormName = item.id;
        }

      })
      this.formData = {};
      for(let k in group){
        group[k].map((item,index)=>{
          if(!this.formData[item.id]){
            this.$set(this.formData,item.id,{
              value:"1",
              des:"",
              groupName:k,
              name:item.name,
              type:item.type
            })
            this.$set(this.fileList,item.id,[])
          }
        })
      }
      this.$set(this.formData,"check",{
        value:null,
        des:""
      })
      this.groupForm = group;
    },
    addCheck(items){
      if(!this.customer){
        this.customer = [];
      }
      items.map(i=>{
        i.value = null
        return i;
      })
      this.$set(this.customer,this.customer.length,JSON.parse(JSON.stringify(items)));

      console.log(items,"items",this.customer)
    },
    resetFormData(){
      this.pics = [];
      this.venueData.checkedSign = "";
      for(let k in this.formData){
        this.formData[k] = {
          value:null,
          des:"",
          urls:""
        };
        this.fileList[k] = [];
      }
    },
    onSubmit(values){
      let rectify = [];
      let confirmForm = [];
      let problemList = [];
      for(let k in values){
        if(values[k]=="0"){
          let fileList = [];
          this.fileList[k].map((pic,index)=>{
            fileList.push({
              name: pic.file.name.replace(/image/,new Date().getTime() + k + "-"+  index ),
              content:pic.content
            })
          })
          problemList.push({
            typeId:k,
            checkResult: this.formData[k].des,
            // fileList:JSON.stringify(fileList)
            fileList:fileList
          })
          rectify.push(k);
          if(this.formDict1[k])confirmForm.push(this.formDict1[k])
          if(this.formDict2[k])confirmForm.push(this.formDict2[k])
          if(this.formDict3[k])confirmForm.push(this.formDict3[k])
        }
      }
      if(rectify.length==0){
        this.venueData.status = 0;
      }else{
        this.venueData.status = 1;
      }
      this.venueData.rectify = rectify.join(",");
      // this.venueData.content = JSON.stringify(values);
      this.venueData.content = JSON.stringify(this.formData);
      this.venueData.problemList = problemList;
      this.confirmForm = confirmForm;
      if(rectify.length>0){
        this.noticeShow = true;
        this.des = "有" + rectify.length + "需整改";
      }else{
        this.noticeShow = false
      }
      this.loading = true;
      this.show = true;
    },
    validator(val,rule){
      console.log(val,rule,"validator");
      if(this.formData[rule.ruleId]["value"]=="0"){
        return val.trim()?true:false;
      }
      return true;
    },
    validatorPic(val){
        return (Array.isArray(val)&&val.length>0)?true:false;
    },
    save(values){
      this.venueData.infoId = localStorage.getItem("wljptinfoId");
      this.venueData.checkType = this.venue.checkType;
      let _this = this;
      this.show = true;
      this.temp = false;
      this.venueData.person = localStorage.getItem("wljptuser")
      if(this.pics.length>0){
        this.venueData.picture = this.pics.map(item=>{
          return item.url
        }).join(",");
      }
      this.$http({
        url: "/zuul/cultural/check/",
        method: "post",
        data:this.venueData
      }).then(res=>{
        if(res.data.success){
          _this.checkData = res.data.data;
          this.successPop = true;
          this.seconds = 5;
          _this.timer = setInterval(() => {
            _this.seconds--;
            if(_this.seconds<0){
              _this.successPop = false;
              window.clearInterval(_this.timer);
            }
          }, 1000);
          _this.timeOuter = setTimeout(function(){
            _this.resetFormData();
            _this.$emit("closeReport")
          },5000);

        }
      }).catch(error=>{
        Notify({ type: 'warning', message: '网络问题，请稍后再试' });
      }).finally(res=>{
        this.loading = false;
      })
    },
    failed(rule){
      if(rule.errors && rule.errors.length>0){
        this.$refs.checkForm.scrollToField(rule.errors[0].name)
      }
    },
    cancleConfirm(){
      this.show = false;
      this.loading = false;
    },
    confirm(){
      this.save();
    },
    init(){
      if(this.formDict3.length>0){
        this.loading = false;
        this.show = false;
        this.successPop = false;
        this.hasTemp();
      }else{
        setTimeout(() => {
          this.loading = false;
          this.show = false;
          this.successPop = false;
          this.hasTemp();
        }, 150)
      }

    },
    viewDetail(){
      this.$emit("viewDetail",{
        venue:this.venue,
        check:this.checkData
      })
    },
    tempSave(){
      let _this = this;
      this.$refs.checkForm.resetValidation();
      let problemList = [];
      for(let k in this.formData){
        if(this.formData[k]["value"]=="0"){
          let fileList = [];
          this.fileList[k].map((pic,index)=>{
            fileList.push({
              name:pic.file.name.replace(/image/,new Date().getTime() + k + "-"+  index ),
              content:pic.content
            })
          })
          problemList.push({
            typeId:k,
            checkResult: this.formData[k].des,
            fileList:fileList
          })
        }
      }
      this.venueData.content = JSON.stringify(this.formData);
      this.venueData.problemList = problemList;
      this.venueData.status = -1;
      if(this.pics.length>0){
        this.venueData.picture = this.pics.map(item=>{
          return item.url
        }).join(",");
      }
      this.$http({
        url: "/zuul/cultural/check/",
        method: "post",
        data:this.venueData
      }).then(res=>{
        if(res.data.success){
          this.successPop = true;
          this.temp = true;
          this.seconds = 5;
          _this.timer = setInterval(() => {
            _this.seconds--;
            if(_this.seconds<0){
              _this.successPop = false;
              window.clearInterval(_this.timer);
            }
          }, 1000);
          _this.timeOuter = setTimeout(function(){
            _this.resetFormData();
            _this.$emit("closeReport")
          },5000);

        }
      }).catch(error=>{
        Notify({ type: 'warning', message: '网络问题，请稍后再试' });
      }).finally(res=>{

      })
    },
    goToMap(){
      this.successPop = false;
      clearTimeout(this.timeOuter);
      this.resetFormData();
      this.$emit("closeReport")
    },
    hasTemp(){
      this.$refs.checkForm.resetValidation();
      this.$http({
        url: "/zuul/cultural/check/hasTemp/" + this.venue.id,
        method: "get"
      }).then(res=>{
        console.log(res,"hasTemp");
        this.tempTime = "";
        if(res.data.success && res.data.data && res.data.data.content){
          this.formData = JSON.parse(res.data.data.content);
          if(res.data.data.problemList && res.data.data.problemList.length>0){
            res.data.data.problemList.map(item=>{
              let arr = [];
              item.recordFileList.map(pic=>{
                arr.push({
                  url:pic,
                  isImage: true
                })
              })
              this.fileList[item.typeId] =arr;
            })
          }
          this.pics = [];
          if(res.data.data.picture){
            let p = res.data.data.picture.split(",");
            p.map(item=>{
              this.pics.push({
                url:item,
                isImage:true
              })
            })
          }
          console.log(this.fileList);
          this.tempTime ="暂存时间："+ res.data.data.createTime.substr(0,16);
        }else{
          this.resetFormData();
        }
      }).catch(error=>{
        this.tempTime = "";
      })
    },
    beforeRead(file,defaultQuqlity=0.6) {
      let fileSize = file.size/1024/1024;
      let quality = defaultQuqlity;
      if(fileSize<0.5){
        quality = 1;
      }else if(fileSize<1){
        quality = 0.8
      }else if(fileSize<2){
        quality = 0.6
      }else if(fileSize<4){
        quality = 0.3   // 0.4 0.24
      }else if(fileSize<6){
        quality = 0.15  // 0.1 0.06   0.2-0.17
      }else if(fileSize<8.1){
        quality = 0.1
      }else{
        return new Promise((resolve,reject)=>{
          Toast('文件大小不能超过 8M');
          reject({
            msg:"图片不能超过6M"
          })

        })
      }

      return new Promise((resolve) => {
        // compressorjs 默认开启 checkOrientation 选项
        // 会将图片修正为正确方向
        new Compressor(file, {
          quality: quality,
          success(result){
            resolve(result);
          },
          error(err) {
            console.log(err.message);
          },
        });
      });
    },
    afterRead(id){
      return file=>{
        let formData = new FormData();
        formData.append(new Date().getTime() + file.file.name,file.file);
        formData.append("timed",true);
        formData.append("filename",new Date().getTime() + file.file.name);

        if(this.formData[id]['value']=="1"){
          this.$http({
            url: "/zuul/oss/file/cultural/upload",
            method: "post",
            headers: {'Content-Type': "multipart/form-data"},
            data:formData
          }).then(res=>{
            if(res.data.success){
              this.fileList[id][this.fileList[id].length>0?(this.fileList[id].length - 1):0].url = '/zuul' +res.data.data[0].viewUrl;
              this.formData[id]["urls"] = this.fileList[id].map(item=>{return item.url}).join(",");
            }
          }).catch(e=>{
            Notify({ type: 'warning', message: '网络问题，请稍后再试' });
          })
        }
      }
    },
    sendFile(file){
      console.log(file.file,"sendFile")
      let formData = new FormData();
      formData.append(new Date().getTime() + file.file.name,file.file);
      formData.append("timed",true);
      formData.append("filename",new Date().getTime() + file.file.name);
      this.$http({
        url: "/zuul/oss/file/cultural/upload",
        method: "post",
        headers: {'Content-Type': "multipart/form-data"},
        data:formData
      }).then(res=>{
        if(res.data.success){
          this.pics[this.pics.length>0?(this.pics.length - 1):0].url = '/zuul' +res.data.data[0].viewUrl;
        }
      }).catch(e=>{
        Notify({ type: 'warning', message: '网络问题，请稍后再试' });
      })
    },
    openSign(value){
      this.signer = value;
      this.signShow = true;
    },
    getSign(value){
      this.venueData[value.value] = value.content;
      if(value.value=='checkerSign'){
        this.checkerSigned = true;
      }else{
        this.checkedSigned = true;
      }
      this.signShow = false;
    }
  },
  mounted(){
    this.venueData.remark = localStorage.getItem("wljptuserName");
    this.venueData.person = localStorage.getItem("wljptuser");
    this.getDictList();
  },
  beforeDestroy() {
    if(this.timer){
      window.clearInterval(this.timer);
    }
  },
}
</script>

<style scoped lang="less">

.form-title{
  color: rgba(69, 90, 100, 0.6);
  line-height: 45px;
  padding: 5px 16px 0px;
  background: #f7f8fa;
}
.form-label{
  padding: 10px 12px 0px;
  color: #646566;
}
.label-tips{
  position: relative;
  left: calc(-100vw + 152px);
  color: red;
  position: relative;
  top: 3px;
}
.confirm-item{
  padding: 5px 20px;
  color: #323233;
}
.model-title{
  letter-spacing: 2px;
  font-weight: 800;
  text-align: center;
  line-height: 50px;
  position: relative;
  .van-icon{
    position: absolute;
    left: 5px;
    top: 10px;
    font-weight: normal;
    color: #969799;
  }
}
.success-icon{
  text-align: center;
  position: relative;
  top: 120px;
}
.success-content{
  padding: 150px 20px;
  text-align: center;
}
.temp-save-btn{
  display: inline-block;
  box-sizing: border-box;
  width:100% ;
  border: 1px solid #ebedf0;
  height: calc(100% - 2px);
  font-size: 16px;
  text-align: center;
  line-height:50px;
  background: #fff;
  //border-radius: 16px;
}
/deep/ .form-image .van-field__error-message{
  top: 60px;
  left:90px;
}
/deep/ .van-cell__title{
  padding-left: 8px;
}
/deep/ .van-radio-group--horizontal{
  width: 150px;
}
/deep/ .van-field__error-message{
  position: absolute;
  top: 1px;
  left: -23px;
}
/deep/ .van-dialog{
  width: calc(100vw - 20px);
}
/deep/ .van-dialog__header{
  padding-bottom: 10px;
}
/deep/ .van-dialog__footer{
  padding: 10px 0px;
}
</style>
